<template>
  <div class="page">
    <router-link class="one_name2"  v-for="( item,index) in list" :key="index" :to="{name : 'name_detail', query : {name : item.word}}">
      <div :class="'name word_'+ item.color" >{{item.word}}</div>
      <div class="desc">
        <p>{{item.desc}}</p>
      </div>
    </router-link>
    <p v-if="!list.length && !loading2" class="tips">您还没有收藏名字呢</p>
    <p v-if="page===-1  && !loading2" class="tips">-- End --</p>
    <button v-if="page !==-1 && page !==1 && !loading2" class="in_body black" @click="get_lists">查看更多</button>

    <ul id="last_right_c" class="last_right">
      <li  @click="xml_go_to_only" data-value="index"  class="home"><img src="@/assets/img/home.png" /></li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios'
import Utils from '@/utils/utils'

export default {
  name: 'name_mark',
  data () {
    return {
      loading2: false,
      list: [],
      page: 1
    }
  },
  created () {
    this.get_lists()
  },
  methods: {
    get_lists () {
      if (this.loading2 || this.page === -1) {
        return
      }
      axios.post('/get_mark_names/', { tag: 'all', page: this.page.all })
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          this.loading2 = false
          const ResList = res.data.data.list
          if (ResList.length === 20) {
            this.list = this.list.concat(ResList)
            this.page = this.page + 1
          } else if (ResList.length !== 0) {
            this.list = this.list.concat(ResList)
            this.page = -1
          } else {
            this.page = -1
          }
        })
    },
    xml_go_to_only (e) {
      Utils.go_to(e)
    }
  }
}
</script>

<style scoped>

.one_name2{
  margin: 0.40rem 0rem;
  padding: 0.40rem 0.60rem;
  display: block;
  background-color: #fefefe;
  text-align: left;
}

.one_name2 div{
  display: inline-block;
  vertical-align: middle;
}

.one_name2 .name{
  text-align: center;
  height: 1rem;
  width: 2.5rem;
  font-size: 0.60rem;
  line-height: 1rem;
  margin-right: 0.16rem;

}

.one_name2 .desc p{
  display: block;
  color: #212b36;
  font-size: 0.36rem;
  max-width: 5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

</style>
